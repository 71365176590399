import React from 'react';
import { Button, Card, Grid, Typography, Zoom } from '@mui/material';
import { Line } from '../../../../../styles';
import {
	Apple,
	DownloadForOfflineOutlined,
	ExtensionRounded,
	Facebook,
	Instagram,
	ShareOutlined,
	Shop,
	SupportAgentRounded,
} from '@mui/icons-material';
import { usePO } from '../../../../../utils/POContext';
import { t } from 'i18next';
import { OfferToolTip } from '../../../../../helpers';

const TopMainContent: React.FC = () => {
	const { selectedTheme } = usePO();

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const cardStyles = {
		backgroundColor:
			selectedTheme.id === 'dark' ? selectedTheme.gradient : '#fafafa',
		border:
			selectedTheme.id === 'dark'
				? `1px solid ${selectedTheme.footerLine}`
				: 'none',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		'&:hover': {
			boxShadow: '1px 1px 4px 1px rgba(160,160,160,0.9)',
		},
		height: '100%',
	};

	const renderContact = () => {
		return (
			<Card sx={cardStyles}>
				<Line
					style={{
						width: '100%',
						justifyContent: 'flex-start',
						padding: '10px 15px',
					}}
				>
					<SupportAgentRounded
						fontSize="medium"
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					/>
				</Line>
				<Line
					style={{
						width: '100%',
						justifyContent: 'flex-start',
						padding: '0px 15px',
					}}
				>
					<Typography
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{t('Inicio.Dashboard.Fale com um consultor')}
					</Typography>
				</Line>
				<Line
					style={{
						width: '100%',
						justifyContent: 'flex-start',
						padding: '10px 15px',
					}}
				>
					<Button
						onClick={() =>
							window.open('https://www.offertech.com.br/', '_blank')
						}
						variant="contained"
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							border: handleBorderButton(),
						}}
					>
						<Typography
							style={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							{t('Inicio.Dashboard.Consultor')}
						</Typography>
					</Button>
				</Line>
			</Card>
		);
	};

	const renderSocialMedia = () => {
		return (
			<Card sx={cardStyles}>
				<Line
					style={{
						width: '100%',
						justifyContent: 'flex-start',
						padding: '10px 15px',
					}}
				>
					<ShareOutlined
						fontSize="medium"
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					/>
				</Line>
				<Line
					style={{
						width: '100%',
						justifyContent: 'flex-start',
						padding: '0px 15px',
					}}
				>
					<Typography
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{t('Inicio.Dashboard.Acompanhe nossas redes sociais!')}
					</Typography>
				</Line>
				<Line
					style={{
						width: '100%',
						justifyContent: 'flex-start',
						padding: '10px 15px',
						gap: 10,
					}}
				>
					<OfferToolTip
						title={'Facebook'}
						placement="bottom"
						arrow
						enterDelay={200}
						enterNextDelay={200}
						TransitionComponent={Zoom}
					>
						<Button
							onClick={() =>
								window.open(
									'https://www.facebook.com/offertechnologies',
									'_blank'
								)
							}
							variant="contained"
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
								border: handleBorderButton(),
								gap: 10,
							}}
						>
							<Facebook />
						</Button>
					</OfferToolTip>
					<OfferToolTip
						title={'Instagram'}
						placement="bottom"
						arrow
						enterDelay={200}
						enterNextDelay={200}
						TransitionComponent={Zoom}
					>
						<Button
							onClick={() =>
								window.open(
									'https://www.instagram.com/offertechnologies/',
									'_blank'
								)
							}
							variant="contained"
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
								border: handleBorderButton(),
								gap: 10,
							}}
						>
							<Instagram />
						</Button>
					</OfferToolTip>
				</Line>
			</Card>
		);
	};

	const renderProducts = () => {
		return (
			<Card sx={cardStyles}>
				<Line
					style={{
						width: '100%',
						justifyContent: 'flex-start',
						padding: '10px 15px',
					}}
				>
					<DownloadForOfflineOutlined
						fontSize="medium"
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					/>
				</Line>
				<Line
					style={{
						width: '100%',
						justifyContent: 'flex-start',
						padding: '0px 15px',
					}}
				>
					<Typography
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{t(
							'Inicio.Dashboard.Baixe nosso app na Google Play, App Store ou Google Extensions'
						)}
					</Typography>
				</Line>
				<Line
					style={{
						width: '100%',
						justifyContent: 'flex-start',
						padding: '10px 15px',
						gap: 10,
					}}
				>
					<OfferToolTip
						title={'Google Store'}
						placement="bottom"
						arrow
						enterDelay={200}
						enterNextDelay={200}
						TransitionComponent={Zoom}
					>
						<Button
							onClick={() =>
								window.open(
									'https://play.google.com/store/apps/details?id=br.com.offeru.go',
									'_blank'
								)
							}
							variant="contained"
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
								border: handleBorderButton(),
								gap: 10,
							}}
						>
							<Shop />
						</Button>
					</OfferToolTip>
					<OfferToolTip
						title={'Apple Store'}
						placement="bottom"
						arrow
						enterDelay={200}
						enterNextDelay={200}
						TransitionComponent={Zoom}
					>
						<Button
							onClick={() =>
								window.open(
									'https://apps.apple.com/br/app/offer-technologies/id1507955604',
									'_blank'
								)
							}
							variant="contained"
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
								border: handleBorderButton(),
								gap: 10,
							}}
						>
							<Apple />
						</Button>
					</OfferToolTip>
					<OfferToolTip
						title={'Google Extensions'}
						placement="bottom"
						arrow
						enterDelay={200}
						enterNextDelay={200}
						TransitionComponent={Zoom}
					>
						<Button
							onClick={() =>
								window.open(
									'https://chrome.google.com/webstore/detail/offer-platform/llcnocgbbgfbhhedinjnboajcmokphbc',
									'_blank'
								)
							}
							variant="contained"
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
								border: handleBorderButton(),
								gap: 10,
							}}
						>
							<ExtensionRounded />
						</Button>
					</OfferToolTip>
				</Line>
			</Card>
		);
	};

	return (
		<Grid container spacing={2} alignItems="stretch">
			<Grid item xs={12} md={4}>
				{renderContact()}
			</Grid>
			<Grid item xs={12} md={4}>
				{renderSocialMedia()}
			</Grid>
			<Grid item xs={12} md={4}>
				{renderProducts()}
			</Grid>
		</Grid>
	);
};

export default TopMainContent;
