import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Footer, Line } from '../../../styles';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import { usePO } from '../../../utils/POContext';
import { api, isAxiosError, setupRequestToken } from '../../../utils/api';
import Loading from '../../../components/LoadingDots';
import { useAuth0 } from '../../../react-auth0-spa';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';

const BuildSearch = () => {
	const { token } = useAuth0();
	const { selectedClient, selectedTheme } = usePO();
	setupRequestToken(api, token);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const useStyles = makeStyles(() => ({
		paper: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
		},
	}));

	const classes = useStyles();

	const searchTypeOptions = [
		{ name: t('Buscas.Tabs.Nova Busca.Buscador'), value: 'BUSCADOR' },
		{ name: 'IDE', value: 'IDE' },
	];

	const [loading, setLoading] = useState(false);

	// Build the search tag: searchTitle|SEARCH|userID
	const [searchTitle, setSearchTitle] = useState<string>('');
	const [formErrors, setFormErrors] = useState<any>({});
	const [selectedPlatforms, setSelectedPlatforms] = useState<any[]>([]);
	const [periodicity, setPeriodicity] = useState<any>({
		value: 7,
		hiddenValue: -1,
	});

	const [searchType, setSearchType] = useState<any>({
		name: t('Buscas.Tabs.Nova Busca.Buscador'),
		value: 'BUSCADOR',
	});
	const [termsToSearch, setTearmsToSearch] = useState<string[]>([]);
	const [excludingTermsToSearch, setExcludingTearmsToSearch] = useState<any[]>(
		[]
	);
	const [existentTag, setExistentTag] = useState<any>({});
	const [description, setDescription] = useState<string>('');
	const [minPrice, setMinPrice] = useState('0,00');
	const [maxPrice, setMaxPrice] = useState('0,00');
	const [category, setCategory] = useState<string>('');
	const [getSolds, setGetSolds] = useState(false);
	const [isUniqueSearch, setIsUniqueSearch] = useState(false);

	const handleResetForm = () => {
		// eslint-disable-next-line no-unused-expressions
		setSearchTitle('');
		setSelectedPlatforms([]);
		setPeriodicity(7);
		setSearchType('BUSCADOR');
		setTearmsToSearch([]);
		setExcludingTearmsToSearch([]);
		setExistentTag(null);
		setDescription('');
		setFormErrors({});
		setMinPrice('');
		setMaxPrice('');
		setCategory('');
		setGetSolds(false);
		setIsUniqueSearch(false);
	};

	//TODO Descomentar quando o backend finalizar a tarefa para o novo padrão
	// const formatQuery = () => {
	// 	let query = `${termsToSearch[0]}?`;

	// 	if (minPrice && Number(minPrice.replace(/\D/g, '')) > 0) {
	// 		const formattedMinPrice = minPrice.replace(/\D/g, '');
	// 		query += `min_price=${formattedMinPrice}&`;
	// 	}

	// 	if (maxPrice && Number(maxPrice.replace(/\D/g, '')) > 0) {
	// 		const formattedMaxPrice = maxPrice.replace(/\D/g, '');
	// 		query += `max_price=${formattedMaxPrice}&`;
	// 	}

	// 	if (category !== '') {
	// 		query += `category=${category}&`;
	// 	}

	// 	if (getSolds === true) {
	// 		query += `get_solds=True&`;
	// 	}

	// 	query = query.endsWith('&') ? query.slice(0, -1) : query;

	// 	return query;
	// };

	const formatQuery = () => {
		let query = `${termsToSearch[0]}`;

		const params: string[] = [];

		if (minPrice && Number(minPrice.replace(/\D/g, '')) > 0) {
			const formattedMinPrice = minPrice.replace(/\D/g, '');
			params.push(`min_price=${formattedMinPrice}`);
		}

		if (maxPrice && Number(maxPrice.replace(/\D/g, '')) > 0) {
			const formattedMaxPrice = maxPrice.replace(/\D/g, '');
			params.push(`max_price=${formattedMaxPrice}`);
		}

		if (category && category !== '') {
			params.push(`category=${category}`);
		}

		if (getSolds === true) {
			params.push(`get_solds=true`);
		}

		if (params.length > 0) {
			query += `?${params.join('&')}`;
		}

		return query;
	};

	const handleCreateTag = async (data) => {
		const tagPayload = {
			name: data.terms,
			description: data.title,
			tagTypeID: searchType.value === 'BUSCADOR' ? 'BUSCA' : 'IDE',
			clientID: selectedClient.id,
		};
		try {
			const searchQuery = formatQuery();
			setLoading(true);
			const tagResponse = await api.post(`/tags`, tagPayload);
			const searchPayload = {
				title: data.title,
				description: data.description,
				platformINcountryID: selectedPlatforms.map((el) => el.inCountryID),
				tagID: tagResponse.data.value.id,
				terms: searchQuery,
				excludingTerms: excludingTermsToSearch,
				period: periodicity.value,
				searchTypeID: searchType.value,
				ISactive: true,
			};
			const searchResponse = await api.post(
				`/searches/clients/${selectedClient.id}`,
				searchPayload
			);
			setLoading(false);
			enqueueSnackbar(t(searchResponse.data.detail), { variant: 'success' });
			handleResetForm();
		} catch (error) {
			// Type guard with "type predicate"
			if (isAxiosError(error)) {
				if (error.response) {
					if (error.response.status === 409) {
						enqueueSnackbar(
							`${selectedClient?.name} ${t(
								`Buscas.Tabs.Nova Busca.já possui uma busca cadastrada com estes termos. Acesse a aba 'Buscas Salvas' para repetir a busca, realizar modificações nos parâmetros ou excluir os resultados anteriores.`
							)}`,
							{
								variant: 'warning',
							}
						);
					}
					setLoading(false);
					try {
						enqueueSnackbar(
							t(
								'Buscas.Tabs.Nova Busca.Termo de busca já cadastrado. Utilize o campo chave de busca existente ou cadastre um novo termo.'
							),
							{
								variant: 'error',
							}
						);
					} catch {
						enqueueSnackbar(t('Erro interno. Por favor tente novamente'), {
							variant: 'success',
						});
					}
				}
			}
		}
	};
	const handleSendData = async (data) => {
		try {
			const searchQuery = formatQuery();
			setLoading(true);
			const searchPayload = {
				title: data.title,
				description: data.description,
				platformINcountryID: selectedPlatforms.map((el) => el.inCountryID),
				tagID: existentTag.id,
				terms:
					existentTag !== null && termsToSearch.length > 0
						? searchQuery
						: existentTag.name,
				excludingTerms: excludingTermsToSearch,
				period: periodicity.value,
				searchTypeID: searchType.value,
				ISactive: true,
			};
			const searchResponse = await api.post(
				`/searches/clients/${selectedClient.id}`,
				searchPayload
			);
			setLoading(false);
			enqueueSnackbar(t(searchResponse.data.detail), {
				variant: 'success',
			});
			handleResetForm();
		} catch (error) {
			// Type guard with "type predicate"
			if (isAxiosError(error)) {
				if (error.response) {
					if (error.response?.status === 409) {
						enqueueSnackbar(
							`${selectedClient?.name} ${t(
								`Buscas.Tabs.Nova Busca.já possui uma busca cadastrada com estes termos. Acesse a aba 'Buscas Salvas' para repetir a busca, realizar modificações nos parâmetros ou excluir os resultados anteriores.`
							)}`,
							{
								variant: 'warning',
							}
						);
					}
					setLoading(false);
					try {
						enqueueSnackbar(
							t(
								'Buscas.Tabs.Nova Busca.Termo de busca já cadastrado. Utilize o campo chave de busca existente ou cadastre um novo termo.'
							),
							{
								variant: 'error',
							}
						);
					} catch {
						enqueueSnackbar(t('Erro interno. Por favor tente novamente'), {
							variant: 'error',
						});
					}
				}
			}
		}
	};

	async function handleSubmit() {
		const formData = {
			title: searchTitle,
			terms: termsToSearch[0], // HardCoded when be just a search term
			excludingTerms: excludingTermsToSearch,
			platforms: selectedPlatforms,
			selectedExistentTag: existentTag,
			periodicity: periodicity,
			searchType: searchType,
			description: description,
		};

		try {
			const schema = Yup.object().shape({
				title: Yup.string()
					.min(
						2,
						t(
							'Buscas.Tabs.Nova Busca.O nome da busca precisa ter no mínimo 2 caracteres'
						)
					)
					.required(t('Buscas.Tabs.Nova Busca.O nome da busca é obrigatório')),
				terms: Yup.string()
					.trim()
					.required(t('Buscas.Tabs.Nova Busca.Um termo não pode ser vazio'))
					.min(1, t('Buscas.Tabs.Nova Busca.O termo de busca é obrigatório')),
				platforms: Yup.array()
					.of(Yup.object().shape({}))
					.min(1, t('Buscas.Tabs.Nova Busca.Plataforma é obrigatório')),
				selectedExistentTag: Yup.object().notRequired(),
				excludingTerms: Yup.array().of(Yup.string()).notRequired(),
				searchType: Yup.object().required(
					t('Buscas.Tabs.Nova Busca.O tipo de busca é obrigatório')
				),
				periodicity: Yup.object().notRequired(),
				description: Yup.string().notRequired(),
			});
			await schema.validate(formData, {
				abortEarly: false,
			});
			setFormErrors({});

			formData.terms = formatQuery();

			if (termsToSearch.length > 0 && existentTag === null) {
				handleCreateTag(formData);
			} else {
				handleSendData(formData);
			}
		} catch (err) {
			const validationErrors = {};
			if (err instanceof Yup.ValidationError) {
				err.inner.forEach((error: any) => {
					validationErrors[error.path] = error.message;
				});
				setFormErrors(validationErrors);
			}
		}
	}

	const renderFooter = () => (
		<Footer
			style={{
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#f2f2f2',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.overlay3dp
						: selectedTheme.foreground,
			}}
		>
			<Line
				style={{
					justifyContent: 'flex-end',
				}}
			>
				{loading ? (
					<Loading width={90} height={35} loop />
				) : (
					<Button
						aria-label="submit-button"
						size="small"
						variant="contained"
						type="submit"
						style={{
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
							color:
								selectedTheme.tone === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
						}}
					>
						{t('Buscas.Tabs.Nova Busca.Salvar')}
					</Button>
				)}
			</Line>
		</Footer>
	);

	// TODO Align textfields when error messages are displayed
	return (
		<>
			<Form
				placeholder=""
				onSubmit={handleSubmit}
				onPointerEnterCapture={undefined}
				onPointerLeaveCapture={undefined}
			>
				<Line
					className={classes.paper}
					style={{
						height: '100%',
						alignItems: 'start',
						padding: '40px 30px',
					}}
				>
					<LeftColumn
						buildingSearch
						searchTitle={searchTitle}
						setSearchTitle={setSearchTitle}
						formErrors={formErrors}
						selectedPlatforms={selectedPlatforms}
						setSelectedPlatforms={setSelectedPlatforms}
						termsToSearch={termsToSearch}
						setTearmsToSearch={setTearmsToSearch}
						excludingTermsToSearch={excludingTermsToSearch}
						setExcludingTermsToSearch={setExcludingTearmsToSearch}
						setDescription={setDescription}
						category={category}
						setCategory={setCategory}
						minPrice={minPrice}
						maxPrice={maxPrice}
						setMinPrice={setMinPrice}
						setMaxPrice={setMaxPrice}
						setGetSolds={setGetSolds}
						getSolds={getSolds}
					/>
					<RightColumn
						buildingSearch
						selectedPlatforms={selectedPlatforms}
						setSelectedPlatforms={setSelectedPlatforms}
						displayExistentTag
						periodicity={periodicity}
						setPeriodicity={setPeriodicity}
						searchType={searchType}
						setSearchType={setSearchType}
						searchTypeOptions={searchTypeOptions}
						setExistentTag={setExistentTag}
						existentTag={existentTag}
						formErrors={formErrors}
						setDescription={setDescription}
						isUniqueSearch={isUniqueSearch}
						setIsUniqueSearch={setIsUniqueSearch}
					/>
				</Line>
				{renderFooter()}
			</Form>
		</>
	);
};
export default BuildSearch;
