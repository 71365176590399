import React from 'react';
import { Button, Card, Grid, Typography } from '@mui/material';
import { Line } from '../../../../../styles';
import {
	ShieldOutlined,
	TagFacesOutlined,
	TroubleshootOutlined,
} from '@mui/icons-material';
import LogoOffer from '../../../../../assets/images/svg/OfferOwl.svg';
import { usePO } from '../../../../../utils/POContext';
import { t } from 'i18next';

const BottomMainContent: React.FC = () => {
	const { selectedTheme } = usePO();

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const renderNews = () => {
		return (
			<Card
				sx={{
					backgroundColor:
						selectedTheme.id === 'dark' ? selectedTheme.gradient : '#fafafa',
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
					'&:hover': {
						boxShadow: '1px 1px 4px 1px rgba(160,160,160,0.9)',
					},
					height: '100%',
				}}
			>
				<Line
					style={{
						width: '100%',
						justifyContent: 'flex-start',
						padding: '10px 20px',
					}}
				>
					<Typography
						style={{
							fontWeight: 'bold',
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{t('Inicio.Dashboard.Destaques do LinkedIn')}
					</Typography>
				</Line>
				<Line style={{ display: 'flex', alignItems: 'center', padding: 20 }}>
					<Card
						style={{
							width: 80,
							height: 80,
							borderRadius: 4,
							padding: 16,
							backgroundColor: '#333',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginRight: 10,
						}}
					>
						<img
							src={LogoOffer}
							style={{
								width: 28,
								height: 35.77,
							}}
						/>
					</Card>
					<Line
						style={{
							display: 'flex',
							flex: 1,
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Typography
							style={{
								flex: 1,
								marginRight: 20,
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t(
								'Inicio.Dashboard.Proteger sua gestão é fundamental para preservar a integridade e o sucesso de sua marca'
							)}
						</Typography>
						<Button
							variant="contained"
							style={{
								whiteSpace: 'nowrap',
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
								border: handleBorderButton(),
							}}
						>
							<Typography>{t('Inicio.Dashboard.Saiba mais')}</Typography>
						</Button>
					</Line>
				</Line>

				<Line style={{ display: 'flex', alignItems: 'center', padding: 20 }}>
					<Card
						style={{
							width: 80,
							height: 80,
							borderRadius: 4,
							padding: 16,
							backgroundColor: '#333',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginRight: 10,
						}}
					>
						<img
							src={LogoOffer}
							style={{
								width: 28,
								height: 35.77,
							}}
						/>
					</Card>
					<Line
						style={{
							display: 'flex',
							flex: 1,
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Typography
							style={{
								flex: 1,
								marginRight: 20,
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t(
								'Inicio.Dashboard.Você sabia que aproximadamente 30% dos anúncios monitorados pela Offer nas principais plataformas de vendas e redes sociais são Ilegais ou piratas.'
							)}
						</Typography>
						<Button
							variant="contained"
							style={{
								whiteSpace: 'nowrap',
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
								border: handleBorderButton(),
							}}
						>
							<Typography>{t('Inicio.Dashboard.Saiba mais')}</Typography>
						</Button>
					</Line>
				</Line>
				<Line style={{ display: 'flex', alignItems: 'center', padding: 20 }}>
					<Card
						style={{
							width: 80,
							height: 80,
							borderRadius: 4,
							padding: 16,
							backgroundColor: '#333',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginRight: 10,
						}}
					>
						<img
							src={LogoOffer}
							style={{
								width: 28,
								height: 35.77,
								color: '#FFEB3B',
							}}
						/>
					</Card>
					<Line
						style={{
							display: 'flex',
							flex: 1,
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Typography
							style={{
								flex: 1,
								marginRight: 20,
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t(
								'Inicio.Dashboard.Contratar um serviço de proteção de marca não é apenas uma medida preventiva, mas um investimento estratégico para o sucesso sustentável do seu negócio.'
							)}
						</Typography>
						<Button
							size="medium"
							variant="contained"
							style={{
								whiteSpace: 'nowrap',
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
								border: handleBorderButton(),
							}}
						>
							<Typography>{t('Inicio.Dashboard.Saiba mais')}</Typography>
						</Button>
					</Line>
				</Line>
			</Card>
		);
	};

	const renderSolutions = () => {
		return (
			<Card
				sx={{
					backgroundColor:
						selectedTheme.id === 'dark' ? selectedTheme.gradient : '#fafafa',
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
					'&:hover': {
						boxShadow: '1px 1px 4px 1px rgba(160,160,160,0.9)',
					},
					height: '100%',
				}}
			>
				<Line
					style={{
						width: '100%',
						justifyContent: 'flex-start',
						padding: '10px 20px',
					}}
				>
					<Typography
						style={{
							fontWeight: 'bold',
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{t('Inicio.Dashboard.Soluções OfferTech')}
					</Typography>
				</Line>
				<Line style={{ display: 'flex', alignItems: 'center', padding: 20 }}>
					<Card
						style={{
							width: 80,
							height: 80,
							borderRadius: 4,
							padding: 16,
							backgroundColor: '#333',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<ShieldOutlined
							style={{
								width: 28,
								height: 35.77,
								top: 22.15,
								left: 26,
								color: '#FFEB3B',
							}}
						/>
					</Card>
					<Line
						style={{
							marginLeft: 10,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
					>
						<Typography
							style={{
								fontWeight: 'bold',
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							{t('Inicio.Dashboard.Combate à pirataria')}
						</Typography>
						<Typography
							style={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t(
								'Inicio.Dashboard.Proteja sua marca com nossa solução contra pirataria.Tecnologia avançada para eliminar produtos falsificados.'
							)}
						</Typography>
					</Line>
				</Line>
				<Line style={{ display: 'flex', alignItems: 'center', padding: 20 }}>
					<Card
						style={{
							width: 80,
							height: 80,
							borderRadius: 4,
							padding: 16,
							backgroundColor: '#333',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<TroubleshootOutlined
							style={{
								width: 28,
								height: 35.77,
								top: 22.15,
								left: 26,
								color: '#FFEB3B',
							}}
						/>
					</Card>
					<Line
						style={{
							marginLeft: 10,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
					>
						<Typography
							style={{
								fontWeight: 'bold',
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							{t('Inicio.Dashboard.Monitoramento de e-commerce')}
						</Typography>
						<Typography
							style={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t(
								'Inicio.Dashboard.Fique à frente da concorrência. Monitore violações de marca e preços irregulares online.'
							)}
						</Typography>
					</Line>
				</Line>
				<Line style={{ display: 'flex', alignItems: 'center', padding: 20 }}>
					<Card
						style={{
							width: 80,
							height: 80,
							borderRadius: 4,
							padding: 16,
							backgroundColor: '#333',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<TagFacesOutlined
							style={{
								width: 28,
								height: 35.77,
								top: 22.15,
								left: 26,
								color: '#FFEB3B',
							}}
						/>
					</Card>
					<Line
						style={{
							marginLeft: 10,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
					>
						<Typography
							style={{
								fontWeight: 'bold',
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							Perfis e sites fakes
						</Typography>
						<Typography
							style={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t(
								'Inicio.Dashboard.Proteja sua imagem online. Detectamos e removemos perfis e sites falsos.'
							)}
						</Typography>
					</Line>
				</Line>
			</Card>
		);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				{renderNews()}
			</Grid>
			<Grid item xs={12} md={6}>
				{renderSolutions()}
			</Grid>
		</Grid>
	);
};

export default BottomMainContent;
